html, body {
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #000;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

.hidden {
  visibility: hidden;
}

.centerfixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.modal {
  background-color: green;
  padding: 30px;
  box-shadow: 2px 2px 5px #0009;
}

#wintext h4 {
  text-align: center;
  color: #d6d6d6;
  margin: 0;
  padding: 32px;
}

.woodgradient {
  color: #fff;
  background: #281a15 padding-box padding-box;
  border: 10px solid #0000;
  position: relative;
}

.elevation-1 {
  z-index: 2;
}

.play {
  color: #fff;
  text-transform: uppercase;
  background-color: #281a15;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  text-decoration: none;
  box-shadow: 2px 2px 5px #0009;
}

.play:focus, .play:active {
  cursor: pointer;
  background-color: #21130f;
}

.play:last-child {
  margin-bottom: 0;
}

.col {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#myapp {
  line-height: 0;
}

.woodgradient:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, #3c241f 0%, #643227 1%, #3c241f 4%, #643227 5%, #3c241f 9%, #643227 10%, #3c241f 14%, #643227 15%, #3c241f 19%, #643227 20%, #3c241f 24%, #643227 25%, #3c241f 29%, #643227 30%, #3c241f 34%, #643227 35%, #3c241f 39%, #643227 40%, #3c241f 44%, #643227 45%, #3c241f 49%, #643227 50%, #3c241f 54%, #643227 55%, #3c241f 59%, #643227 60%, #3c241f 64%, #643227 65%, #3c241f 69%, #643227 70%, #3c241f 74%, #643227 75%, #3c241f 79%, #643227 80%, #3c241f 84%, #643227 85%, #3c241f 89%, #643227 90%, #3c241f 94%, #643227 95%, #3c241f 99%, #643227 100%);
  margin: -10px;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.8f363720.css.map */
