html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: black;

    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.5em;
}
.hidden {
    visibility: hidden;
}
.centerfixed {
    /* margin: 0 auto; */
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.modal {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    padding: 30px;
    /* border-radius: 10px; */
    background-color: green;
}
#wintext h4 {
    padding: 32px;
    text-align: center;
    color: #d6d6d6;
    margin: 0;
}

.woodgradient {
    position: relative;

    color: #fff;
    background: rgba(40, 26, 21, 1);
    background-clip: padding-box; /* !importanté */
    border: solid 10px transparent; /* !importanté */
}

.elevation-1 {
    z-index: 2;
}

.play {
    color: white;
    padding: 10px;
    background-color: rgba(40, 26, 21, 1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 10px;
    border: none;
}
.play:focus,
.play:active {
    background-color: rgba(33, 19, 15, 1);
    cursor: pointer;
}
.play:last-child {
    margin-bottom: 0px;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#myapp {
    line-height: 0;
}

.woodgradient:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -10px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(
        90deg,
        rgba(60, 36, 31, 1) 0%,
        rgba(100, 50, 39, 1) 1%,
        rgba(60, 36, 31, 1) 4%,
        rgba(100, 50, 39, 1) 5%,
        rgba(60, 36, 31, 1) 9%,
        rgba(100, 50, 39, 1) 10%,
        rgba(60, 36, 31, 1) 14%,
        rgba(100, 50, 39, 1) 15%,
        rgba(60, 36, 31, 1) 19%,
        rgba(100, 50, 39, 1) 20%,
        rgba(60, 36, 31, 1) 24%,
        rgba(100, 50, 39, 1) 25%,
        rgba(60, 36, 31, 1) 29%,
        rgba(100, 50, 39, 1) 30%,
        rgba(60, 36, 31, 1) 34%,
        rgba(100, 50, 39, 1) 35%,
        rgba(60, 36, 31, 1) 39%,
        rgba(100, 50, 39, 1) 40%,
        rgba(60, 36, 31, 1) 44%,
        rgba(100, 50, 39, 1) 45%,
        rgba(60, 36, 31, 1) 49%,
        rgba(100, 50, 39, 1) 50%,
        rgba(60, 36, 31, 1) 54%,
        rgba(100, 50, 39, 1) 55%,
        rgba(60, 36, 31, 1) 59%,
        rgba(100, 50, 39, 1) 60%,
        rgba(60, 36, 31, 1) 64%,
        rgba(100, 50, 39, 1) 65%,
        rgba(60, 36, 31, 1) 69%,
        rgba(100, 50, 39, 1) 70%,
        rgba(60, 36, 31, 1) 74%,
        rgba(100, 50, 39, 1) 75%,
        rgba(60, 36, 31, 1) 79%,
        rgba(100, 50, 39, 1) 80%,
        rgba(60, 36, 31, 1) 84%,
        rgba(100, 50, 39, 1) 85%,
        rgba(60, 36, 31, 1) 89%,
        rgba(100, 50, 39, 1) 90%,
        rgba(60, 36, 31, 1) 94%,
        rgba(100, 50, 39, 1) 95%,
        rgba(60, 36, 31, 1) 99%,
        rgba(100, 50, 39, 1) 100%
    );
}
